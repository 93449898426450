import React from "react";
import DelayLink from "/components/core/DelayLink";
import { KYCStatus } from "/api/user/kyc-status";
/**
 * 
 * @param {{ 
 * kycStatus: import("/api/user/kyc-status").UseKycStatus,
 * onClose: () => void 
 * }} param0 
 * @returns 
 */
export default function KYC({ kycStatus, onClose: closePopUp }) {
  return (
    <>
      <p className="fixel_22 text-center">
        In order to be able to deposit $TICO, <br />
        you need to have completed your KYC.<br /><br />
        <b>{!kycStatus.isLoading ? KYCStatus.getLabelFor(kycStatus.data?.kyc_status) + "." : ""}</b>
      </p>
      {KYCStatus.isBanned(kycStatus.data?.kyc_status) ? <></> :
      (<DelayLink
        to={"/profile/verification"}
        className="def type-40 br-16 pr as-c go-to-kyc"
        title="Complete KYC"
        cd={() => {
          setTimeout(closePopUp, 250);
        }}
      >
        {"Complete KYC"}
      </DelayLink>)}
    </>
  );
}
