function showMessage(e, info, cb) {
  const { target, type } = e;
  const show = type == "mouseenter";

  info.classList.toggle("shoved", show);
  if (!show) return;
  const header = document.querySelector("header");
  const { top, left, width, height } = target.getBoundingClientRect();
  const showTop = top > info.clientHeight + header.clientHeight;
  info.classList.toggle("show-top", showTop);
  info.classList.toggle("show-bottom", !showTop);

  info.style.top = `${top + height * (showTop ? 0 : 1)}px`;
  info.style.left = `${left + width / 2}px`;
  cb?.();
}

export default showMessage;
