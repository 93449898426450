import * as Sentry from "@sentry/react";

// todo: https://docs.sentry.io/platforms/javascript/guides/react/#react-19-error-reporting
if (
  import.meta.env.VITE_ENVIRONMENT &&
  !import.meta.env.VITE_REACT_SUPPRESS_SENTRY
) {
  Sentry.init({
    dsn: "https://99b46a3a1969aca19dbad17dff15a007@o4506519499309056.ingest.us.sentry.io/4507435565056000",
    integrations: [
      // Sentry.replayIntegration(),
      // Sentry.feedbackIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: "system",
      // }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.3,
    environment: import.meta.env.VITE_ENVIRONMENT,
  });
}
