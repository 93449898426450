import React, { useEffect, useRef, useState } from "react";
import CustomButton from "/components/core/ButtonDefault";
import CopyToClipboard from "react-copy-to-clipboard";
import gsap from "gsap/all";

let sto = null;

const CopyText = ({ title, addr, copyed, onCopy }) => {
  return (
    <>
      <div>
        <p className="fixel_16_md">{title} </p>
        <div className="df ai-c gap-16">
          <p className="fixel_22 mr-t-4 text-ellipsis">{addr}</p>
          <CopyToClipboard text={addr} onCopy={() => onCopy(addr)}>
            <div
              className={
                "icon_copy icon-24 ar-1" + (copyed == addr ? " copyed" : "")
              }
            ></div>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

export default function Transaction(props) {
  const { onClose, state, data } = props;
  const [copyAddr, setCopyAddr] = useState("");
  const progressRef = useRef();
  const refreshBy = 15;
  const Base64_QR = data.base_qr;

  const onCopy = (addr) => {
    sto && clearTimeout(sto);
    setCopyAddr(addr);
    sto = setTimeout(() => setCopyAddr(""), 1500);
  };

  const onConfirmed = () => {
    //close Wallet popup
    onClose();

    // show popup on Success
    state.setPopUp("confirm", {
      title: "Purchase Completed",
      subtitle:
        "Your transfer has been received! <br> <br> You will be notified as soon as the transaction has been successfully confirmed!",
      close: "Let’s Play!",
    });
  };

  const getTransactionStatus = async () => {
    const url = `cashier/fiat/providers/simplex/create-payment-session`;
    const params = {};

    // const ress = await fetchAPI.post(url, params);  //TODO

    const ress = { success: false };

    return ress.success;
  };

  useEffect(() => {
    const tl = gsap.timeline();
    const progres = progressRef.current;

    tl.to(progres, {
      duration: refreshBy,
      ease: "none",
      transform: "none",
      onComplete: async () => {
        const status = await getTransactionStatus();
        status ? onConfirmed() : tl.restart();
      },
    });

    return () => {
      tl.revert().kill();
    };
  }, []);

  return (
    <>
      <h2 className="fixel_36 text-center">Buy $TICO</h2>

      <div className="df transaction-to-pay w-full ai-st" data-hide-top data-lg>
        <div className="content-left w-full df fd-c">
          <div>
            <p className="fixel_26">Finalize Your Payment</p>
            <p className="fixel_16_sbl mr-t-4">
              Your Order no. {data.uuid} is waiting for payment.
            </p>
          </div>

          <div className="df fd-c content-info">
            <div>
              <p className="fixel_16_md">Amount to be sent </p>
              <p className="fixel_22 mr-t-4">{data.amount} BTC </p>
            </div>

            <CopyText
              title={"to this Address"}
              addr={data.address}
              onCopy={onCopy}
              copyed={copyAddr}
            />
            {data.memo && (
              <CopyText
                title={"Memo"}
                addr={data.memo}
                onCopy={onCopy}
                copyed={copyAddr}
              />
            )}
            <div>
              <p className="fixel_16_md">to receive</p>
              <div className="mr-t-4 df ai-c gap-6">
                <div className="tico_icon"></div>
                <p className="fixel_22 ">{data.amount}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="content-right df fd-c">
          <CustomButton
            classname={`go-buy pr`}
            def={40}
            disabled={true}
            title={"Connect Wallet To Pay"}
          />
          {Base64_QR && (
            <div className="df fd-c ai-c">
              <div className="qr as-c df cover ar-1">
                <img src={Base64_QR} alt="" />
              </div>
              <p className="fixel_16_sbl mr-t-16 text-center">
                Scan this QR code with <br /> your crypto wallet app
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="refresh df fd-c ai-c gap-16 text-center fixel_16_md">
        <div className="df ai-c gap-16">
          <div className="icon_loader"></div>
          <p>Waiting for your transaction... </p>

          <div className="info df">
            <div className="info_message show-top">
              <div className="wrapper br-8 text-center">
                <p className="fixel_14">
                  Once the funds are sent, you <br />
                  can safely close this window.
                </p>
              </div>
            </div>
            <div className="icon_info"></div>
          </div>
        </div>
        <div className="line w-full br-16">
          <div className="progress" ref={progressRef}></div>
        </div>
      </div>
    </>
  );
}
