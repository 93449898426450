const base = import.meta.env.VITE_REACT_APP_BASE_URL

function checkURL(url) {
  if (!url) {
    return url;
  }
  return url?.includes('https:') ? url : base + url
}

export default checkURL
