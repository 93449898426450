import "./instrument.js";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import store from "./redux/state";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import FeatureFlagsProvider from "./context/FeatureFlagsProvider";
import AutoLoginLinkProvider from "./context/AutoLoginLinkProvider";
import ConversionParamsProvider from "./context/ConversionParamsProvider";
import Preloader from "./components/layout/Preloader.jsx";


const root = createRoot(document.getElementById('root'))

let rerenderEntireTree = (state) => {
  root.render(
    <BrowserRouter basename={state.basePath}>
      <ConversionParamsProvider>
        <AuthProvider>
          <AutoLoginLinkProvider>
            <Preloader state={store} />
            <FeatureFlagsProvider>
              {/* <DataProvider> */}
              {/* <GoogleOAuthProvider clientId="837411321679-cgnr28oavedi7jabeiha7elsbcdq8adh.apps.googleusercontent.com"> */}
              {/* <Web3Provider> */}
              <App state={store} />
              {/* </Web3Provider> */}
              {/* </GoogleOAuthProvider> */}
              {/* </DataProvider> */}
            </FeatureFlagsProvider>
          </AutoLoginLinkProvider>
        </AuthProvider>
      </ConversionParamsProvider>
    </BrowserRouter>
  );
}

rerenderEntireTree(store.getState());
store.subscribe(rerenderEntireTree);
