import React, { useEffect } from "react";

export default function Table({ state, data }) {
  useEffect(() => {
    return () => {};
  }, []);

  const handleClose = () => {
    state.setPopUp("");
    data.close_callback && data.close_callback();
  };

  return (
    <>
      <div className="title">
        <p className="t fixel_36 ">{data.title}</p>
      </div>
      {data?.custom_data}
    </>
  );
}

// state.setPopUp("table", {
//   title: "Sell Now",
//   custom_data: (
//     <>
//       <div className="table_grid df fd-c fixel_16_md">
//         <div className="grid">
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//         </div>
//         <div className="line"></div>
//         <div className="grid">
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//           <div className="row">
//             <div className="col key">Name</div>
//             <div className="col value">
//               05hhx1d0a9EcceB3a9Ec05hhx1d0a9EcceB3a9Ec
//             </div>
//           </div>
//         </div>
//         <div className="line"></div>

//         <Input placeholder="Enter your price" type={"number"} />
//       </div>
//       <div className={`corfirm_buttons df jc-c sing`}>
//         <CustomButton
//           classname="red"
//           def={40}
//           title="Sell now"
//           onClick={(e) => state.setPopUp("")}
//         />
//       </div>
//     </>
//   ),
// });
