import useSWRMutation from 'swr/mutation'

/**
 * @typedef {{ }} Data
 * @typedef {{
      amount: string,
      address: string,
      chainId: number,
  }} ExtraArg
  @typedef {{ 'message': 'User has not completed KYC', 'code': 'full_kyc_required' }} Error
 * This address should be gained only after the user has selected the chain manually
 * It make sense to use trusted event to trigger this request, but for now it's fine
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr/mutation").SWRMutationConfiguration<Data, Error>} config
 * @return {import("swr/mutation").SWRMutationResponse<Data, Error, unknown, ExtraArg>}
 */
export function useCreateWithdrawalRequest(axiosClient, config) {
  return useSWRMutation(
    [`cashier/withdrawal/create-withdrawal-request`],
    ([url], { arg: { amount, address, chainId } }) =>
      axiosClient.wrap(
        axiosClient.post(url, {
          amount,
          address,
          chainId
        })
      ),
    config
  )
}
