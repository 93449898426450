import useSWRMutation from 'swr/mutation'

/**
 * @typedef {{
      uuid: "9cb1592a-7832-4e10-9d9a-743165841fd4",
 }} Body
 * @typedef {{ }} Error
 * @param {import("axios").AxiosInstance} axiosClient
 * @param {import("swr/mutation").SWRMutationConfiguration<Body, Error>} config
 * @return {import("swr/mutation").SWRMutationResponse<Body, Error>}
 */
export function usePaymentUUID(axiosClient, config = {}) {
  return useSWRMutation(['cashier/generate-payment-uuid'], ([url]) => axiosClient.wrap(axiosClient.post(url)), {
    // keepPreviousData: true,
    ...config
  })
}
