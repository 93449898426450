import React from "react";

export default function Info(props) {
  return (
    <>
      <p className="fixel_22 text-center">
        Someday there will be some text here
      </p>
    </>
  );
}
