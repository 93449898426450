import React from "react";

/**
 * 
 * @typedef {{
 * from: React.ReactNode,
 * to: React.ReactNode,
 * rate: React.ReactNode
 * }} Props
 * @pure
 * 
 * @param {Props} param0 
* @returns 
*/
export function Rate({ from, to, rate }) {
 return <dl className="df">
   <dt>
     <span>1 </span>
     <span className="uppercase">{from}</span>
   </dt>
   <dd style={{margin: 0}}>
     <span style={{whiteSpace:"pre"}}> = </span>
     <span>{rate} </span>
     <span className="uppercase">{to}</span>
   </dd>
 </dl>
}