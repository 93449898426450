
import React from "react";

/**
 * 
 * @param {React.PropsWithChildren} param0 
 * @returns 
 */
export function TicoAmount({ children }) {
  return <>
    <div className="tico_icon"></div>
    <p className="fixel_22">
      {children}
    </p>
  </>
}