import React, { useContext } from 'react'
import AuthContext from '/context/AuthProvider'

export default function UserRanks({ myref }) {
  const { auth } = useContext(AuthContext)
  const { rank, season } = auth

  if (!rank) return null

  return (
    <div className="user_short_rang" ref={myref}>
      <div className="info df fd-c fixel_14 br-8">
        <div className="row">
          <div className="t">Current Rank</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c">
              {rank?.current?.name}
              {rank?.current?.iconUrl && (
                <div className="ico to df">
                  <img src={rank?.current.iconUrl} alt={rank?.current?.name} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="t">Next Rank</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {rank?.next?.name}
              {rank?.next?.iconUrl && (
                <div className="ico to df">
                  <img src={rank?.next.iconUrl} alt={rank?.next?.name} />
                </div>
              )}
            </div>
            <div className="df ai-c gap-4">
              {rank?.currentXp} <span className="color-neu"> / </span> {rank?.next.requiredXpPoints}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {rank?.next.requiredXpPoints - rank?.currentXp} <div className="icon_xp xs"></div> <span>Needed</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="t">Next rank lv.</div>
          <div className="v fixel_14_sbl df fd-c">
            <div className="df ai-c gap-4">
              {rank?.currentXp} <span className="color-neu"> / </span> {rank?.next.requiredXpPoints}
              <div className="icon_xp xs"></div>
            </div>
            <div className="need df ai-c gap-4">
              {rank?.next.requiredXpPoints - rank?.currentXp} <div className="icon_xp xs"></div> <span>Needed</span>
            </div>
          </div>
        </div>
        {season?.currentXp && (
          <>
            <div className="line w-full"></div>
            <div className="rest df fd-c">
              <div className="row">
                <div className="t">Season</div>
                <div className="v fixel_14_sbl df fd-c">{season?.seasonName}</div>
              </div>

              <div className="row">
                <div className="t">Level</div>
                <div className="v fixel_14_sbl df fd-c">{season?.currentLevel}</div>
              </div>
              <div className="row">
                <div className="t">Progress</div>
                <div className="v fixel_14_sbl df fd-c">
                  <div className="df ai-c gap-4">
                    {season?.currentXp}
                    <span className="color-neu"> / </span> {season?.requiredXpToUnlockNextLevel}
                    <div className="icon_xp xs"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
