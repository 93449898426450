import React from "react";

const soc = [
  { icon: "twitter", link: "https://twitter.com/Funtico_com" },
  { icon: "telegram", link: "https://t.me/FunticoPortal" },
  { icon: "discord", link: "https://discord.gg/kN4kAAHvQd" },
  {
    icon: "facebook",
    link: "https://www.facebook.com/profile.php?id=61551632833409",
  },
  {
    icon: "tiktok",
    link: "https://www.tiktok.com/@funtico.com?_t=8jdJxCTyQrp&_r=1",
  },
  { icon: "instagram", link: "https://www.instagram.com/funtico_com/" },
  { icon: "linkedin", link: "https://www.linkedin.com/company/funtico" },
  { icon: "medium", link: "https://medium.com/@funtico" },
  {
    icon: "youtube",
    link: "https://www.youtube.com/channel/UCKNSppHiWRtKlqxyvAntNwQ",
  },
];
export default function Socials({ clas }) {
  return (
    <div className={`socials ${clas ?? ""}`}>
      {soc.map((social, i) => (
        <a
          key={i}
          href={social.link}
          target="_blank"
          className={"network df mask_" + social.icon}
        ></a>
      ))}
    </div>
  );
}
