import React from "react";

const LoadImage = (props) => {
  if (typeof props === "string") {
    return;
  }

  const { wrapperProps = {}, ...innerProps } = props || {};

  return (
    <div
      {...(wrapperProps ?? {
        style: { width: "100%" },
      })}
    >
      <img
        src={innerProps.src}
        alt={innerProps?.alt}
        loading="lazy"
        decoding="async"
        {...innerProps}
      />
    </div>
  );
};

export default LoadImage;
