import React from "react";
import DelayLink from "/components/core/DelayLink";
import gsap, { ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin);

// DelayLink;
export default function FunticoLogo() {
  const scrollTop = (e) => {
    gsap.to(window, { duration: 0.3, scrollTo: { x: 0, y: 0 } });
  };

  return location.pathname.split("funtico").pop() == "/" ? (
    <button className="fantico_logo df" onClick={scrollTop}>
      <div className="clip_logo"></div>
    </button>
  ) : (
    <DelayLink to={"/"} className="fantico_logo df">
      <div className="clip_logo"></div>
    </DelayLink>
  );
}
