import React, { useEffect, useRef, useState } from "react";
import UserIco from "/assets/img/icons/user.svg";
import CustomButton from "/components/core/ButtonDefault";
import Arrow from "/assets/img/icons/arrow_right.svg";
import User from "/components/core/User";
import { useSearchParams } from "react-router-dom";
import UserBalance from "/components/core/UserBalance";
import InfoMessage from "/components/core/InfoMessage";

export default function UnLoginedButton({
  clas,
  state,
  auth,
  children,
  ...props
}) {
  const [balance, setBalance] = useState(true);
  const mobile = state.getState().isMobile;
  const [searchParams, setSearchParams] = useSearchParams();
  const hoverRef = useRef(null);
  // const { auth } = useContext(AuthContext);

  const openModal = ({ target }) => {
    state.setPopUp(target.dataset.open);
  };

  useEffect(() => {
    const btag = searchParams.get("btag");
    if (!auth?.token && btag) {
      state.setPopUp("reg");
    }
  }, []);

  return (
    <>
      {auth.token ? (
        <div className="short_user_info df">
          <div className="to_profile df ai-c">
            <User
              auth={auth}
              shortInfo={true}
              mobile={mobile}
              info="1"
              state={state}
            />

            <button
              className={`df check_bal ${balance ? "" : "hide"}`}
              onClick={(e) => setBalance(!balance)}
            >
              <Arrow />
            </button>

            {balance ? (
              <div className="balance df fd-c fixel_16_md" ref={hoverRef}>
                <p className="color-neu">Balance</p>
                <UserBalance />
              </div>
            ) : null}
          </div>

          {children}
        </div>
      ) : (
        <div className="buttons_sing df ai-c">
          <CustomButton
            classname="in wt"
            data-open="login"
            onClick={openModal}
            title="Sign In"
          >
            <div className="icon df br-8">
              <UserIco></UserIco>
            </div>
          </CustomButton>
          <span className="line"></span>
          <CustomButton
            classname="reg wt"
            data-open="reg"
            onClick={openModal}
            title="Register"
          />
        </div>
      )}
      {!mobile && balance && auth.token && (
        <InfoMessage target={hoverRef}>
          <div className="tge_balance">
            <div className="info br-8">
              <p className="fixel_16_sbl">Post TGE Balance</p>

              <p className="fixel_14 color-neu mr-t-20">
                1. Your Promissory Bonus $TICO tokens (your current Balance
                before TGE) will be displayed on the platform and credited to
                the your account after the Token Generation Event (TGE).
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                2. You can use the promissory tokens within the platform to play
                games, participate in tournaments, and purchase in-game items or
                NFTs. If the tokens are insufficient for these activities, you
                can deposit additional funds into your account.
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                3. Players cannot withdraw the promissory tokens for the first
                90 days after the TGE. During this period, tokens can only be
                used within the platform. After 90 days, players can withdraw
                the remaining tokens or a portion of them, provided some tokens
                have been used or additional funds have been deposited.
              </p>
              <p className="fixel_14 color-neu mr-t-16">
                4. The tokens are non-transferable within the platform and
                cannot be transferred to any other player or individual.
              </p>
            </div>
          </div>
        </InfoMessage>
      )}
    </>
  );
}
