import { useContext, useState } from 'react'
import useAxiosREST from '/utils/hooks/useAxiosAPI'
import AuthContext from '/context/AuthProvider'
import * as Sentry from '@sentry/browser'

export default function setUserData() {
  const [user, setUser] = useState({})
  const { fetchAPI } = useAxiosREST()
  const { setAuth } = useContext(AuthContext)

  const getUser = async () => {
    const data = await fetchAPI.get('user/profile')

    if (data?.username) {
      const [ranks] = await Promise.allSettled([getRanks()])
      window['user_email'] = data?.email

      const { appearance: app, ...rest } = data
      const userData = {
        avatar: app?.profileAvatar,
        border: app?.profileAvatarBorder,
        cursor: app?.customCursor,
        ...rest,
        all_ranks: ranks?.value?.data
      }

      setAuth((e) => ({ ...e, ...userData }))
      setUser(userData)
    }

    if (import.meta.env.NODE_ENV === 'production' && !import.meta.env.VITE_REACT_SUPPRESS_SENTRY) {
      Sentry.setUser({ email: data?.email, username: data?.username })
    }
  }

  const getRanks = async () => {
    return await fetchAPI.get('user/ranks')
  }

  return { user, getUser, getRanks }
}
