import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollBar } from "/utils/ScrollBarFactory";
import Input from "/components/core/labels/Input";

const labels = {
  name: "currency",
  tip: { title: "in the following currency" },
};

export default function SelectCurrency({
  state,
  value,
  currencys,
  onChange,
  upper,
  ...restProps
}) {
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);
  const mobile = state.getState().isMobile;

  const handleChange = (e) => {
    onChange(e.target.name);
    setOpen(false);
  };
  const onClick = () => {
    setOpen(!open);
  };
  const onBodyClick = ({ target }) => {
    if (!target.closest(".select_currency")) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!open) return;
    let sb = null;
    !mobile &&
      setTimeout(() => {
        const scroller = scrollRef.current;
        sb = ScrollBar.create({ scroller });
      }, 100);

    document.body.addEventListener("click", onBodyClick);

    return () => {
      sb?.kill();
      document.body.removeEventListener("click", onBodyClick);
    };
  }, [open]);

  return (
    <div className="select_currency df ai-c gap-10">
      <div className={"currency-wrapper"} onClick={onClick}>
        <Input
          {...labels}
          value={value}
          className={upper ? " uppercase" : ""}
          {...restProps}
        >
          <div className="icon_arrow ar-1 icon-24" data-open={open}></div>
        </Input>
      </div>

      {open && (
        <div className="currencys w-full">
          <div className="wrapper">
            <div className="scroller df w-full" ref={scrollRef}>
              <div className="grid df fd-c w-full fixel_16_sbl">
                {currencys.map((carr) => {
                  return (
                    <button
                      key={carr}
                      name={carr}
                      className={"df ai-c curr" + (upper ? " uppercase" : "")}
                      disabled={value == carr}
                      onClick={handleChange}
                    >
                      {carr}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
