import React from "react";
import user_icon from "/assets/img/icons/user_no_avatar.svg?url";

export default function UserAvatar(props) {
  const { stroke, hide, type = "" } = props;

  const avatar = props?.avatar || props?.profileAvatar;
  const border = props?.border || props?.profileAvatarBorder;

  const noBorder = border?.url || hide ? "" : stroke ? "" : " border";
  const noAvatar = avatar?.url ? "" : " no_ava";
  return (
    <>
      <div className={`user_appearance${noBorder}${noAvatar} ${type}`}>
        <div className="my_app my_avatar">
          <img
            src={avatar?.url ?? props?.emptyAvatar ?? user_icon}
            alt={avatar?.name}
          />
        </div>

        {border?.url ? (
          <div className="my_app my_border">
            <img src={border.url} alt={border.name} />
          </div>
        ) : null}

        {stroke && !border?.url && <div className="my_stroke"></div>}
        {props.children}
      </div>
    </>
  );
}
