/**
 * @typedef {{
 *  intlFormat: Intl.NumberFormat,
 *  decimals: number,
 * }} FormatInfo
 * Find the length of the fraction part of the currency
 * @example formatInfo("USD") // returns { decimals: 2, intlFormat }
 * @param {string} currencyCode
 * @returns {FormatInfo} - decimals
 */
export function formatInfo(currencyCode) {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
  });
  return {
    intlFormat: numberFormat,
    decimals:
      numberFormat.formatToParts(1).find((part) => part.type === "fraction")
        ?.value.length ?? 0,
  };
}
