import React from "react";
import { logos } from "./logos";
import { payToLocaleString } from "/utils/utils";

/* <label class="provider-option">
<input type="radio" name="provider" value="${provider}" checked /> */

export const ProviderRow = ({
  selected,
  data: { provider, response },
  ...props
}) => {
  return (
    <label
      className={
        "row-providers--provider df fd-c br-12" +
        (selected == provider ? " active" : "")
      }
      key={provider}
      {...props}
    >
      <div className="w-full df jc-sb ai-st">
        <div className="df ai-c gap-10">
          {/* <input type="radio" name="provider" value={provider} checked /> */}
          <div className="row-providers--dot dot ar-1 icon-24"></div>
          <div className="row-providers--logo df cover">
            <div
              className={"row-providers--logo df cover icon_" + logos[provider]}
            >
              {/* <img src={logos[provider]} alt="" /> */}
            </div>
          </div>
        </div>
        <div className="df fd-c ai-en gap-4">
          <p className="fixel_22">
            You Pay: {payToLocaleString(response.from.amount)}{" "}
            <span className="uppercase">{response.from.code}</span>
          </p>
          <p className="fixel_14 color-neu mr-t-4">(Including fees)</p>
        </div>
      </div>
      <div className="df jc-sb fixel_16_md">
        <p className="color-neu">Exchange Rate</p>
        <p className="uppercase">
          1 TICO = {response.rate} {response.from?.code}
        </p>
      </div>
      <div className="full-line w-full"></div>
      <div className="df jc-sb fixel_22">
        <p>You Get</p>
        <p>
          {payToLocaleString(response.to.amount)} {response.to?.code || "TICO"}
        </p>
      </div>
    </label>
  );
};
