import React from "react";
import { useAxiosWithAuth } from "/utils/hooks/useAxiosAPI";
import { AnimatedCounter } from "react-animated-counter";
import { useWithdrawableBalance } from "/api/user/tico-balance";

export default function UserBalance() {
  const axiosClient = useAxiosWithAuth();
  const {
    data: { ticoBalance },
  } = useWithdrawableBalance(axiosClient, {
    fallbackData: {
      ticoBalance: 0.0,
    },
    revalidateOnFocus: false,
  });

  return (
    <>
      <div className="bal mr-t-8 df ai-c gap-10 text-center">
        <div className="tico_icon ico"></div>
        <AnimatedCounter
          value={ticoBalance}
          color="white"
          fontSize="16px"
          decimalPrecision={0}
          decrementColor={"#dc1947"}
          incrementColor={"#b9ff54"}
          includeCommas
        />

        {/* <SlotCounter value={balance} duration={0.5} /> */}
        {/* <div ref={balRef}>0</div> */}
      </div>
    </>
  );
}
