import React from "react";
import SVGGradient from "/components/core/Gradient";

export default function Preloader({ state }) {
  const load = state.getState().isPageLoading;
  const mobile = state.getState().isMobile;

  return (
    <>
      <SVGGradient mobile={mobile} />
      <div className={`preloader ${load ? "active" : ""}`}>
        <div className="fantico_logo">
          <div className="clip_logo"></div>
        </div>
      </div>
    </>
  );
}
