import React, { useEffect, Suspense, useContext } from "react";
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Layout from "./layouts/Layout";
import getUserData from "./components/core/GetUserData";
import AuthContext from "./context/AuthProvider";
import "./assets/scss/index.scss";
import useAxiosREST from "./utils/hooks/useAxiosAPI";
import { checkSearchParams } from "./utils/utils";
import ErrorBoundary from "./ErrorBoundary";
import { routes } from "./routes";
import { gsap, ScrollTrigger, Observer, ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, Observer, ScrollToPlugin);

Object.assign(window, {
  gsap,
  ScrollTrigger,
  Observer,
  ScrollToPlugin,
});

const createRoute =
  (state) =>
  ({ component: Component, children, ...route }) => {
    return (
      <Route
        key={route.path}
        {...route}
        element={
          <Suspense fallback={<div></div>}>
            <ErrorBoundary fallback={<div>An Error occurred</div>}>
              <Component state={state} featureFlag={route.featureFlag} />
            </ErrorBoundary>
          </Suspense>
        }
      >
        {children?.map(createRoute(state))}
      </Route>
    );
  };

export default function App({ state }) {
  const { auth } = useContext(AuthContext);

  const { pathname } = useLocation();

  const params = useSearchParams();
  const navigate = useNavigate();

  const fetch = useAxiosREST();
  const { getUser } = getUserData();

  const runSearchParamsHooks = async () => {
    await checkSearchParams(
      params,
      fetch,
      state,
      auth,
      pathname,
      navigate,
      getUser
    );
  };

  useEffect(() => {
    runSearchParamsHooks();
  }, []);

  useEffect(() => {
    if (auth?.token && !auth?.wallet) {
      getUser();
    }
    if (params[0].get("lf_connect")) {
      runSearchParamsHooks();
    }
  }, [auth.token]);

  return (
    <Routes>
      <Route path="/" element={<Layout state={state} auth={auth} />}>
        {routes?.map(createRoute(state))}
      </Route>
    </Routes>
  );
}
