import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "/components/layout/Header";
import Footer from "/components/layout/Footer";
import Aside from "/components/layout/Aside";
import PopUps from "/components/pop-ups/PopUp";
import { useWindowSize } from "/utils/hooks/use-EventListener";
import { ScrollBar } from "/utils/ScrollBarFactory";
import WalletPopUp from "/components/pop-ups/WalletPopUp";
import SimplexWidget from "/components/widgets/SimplexWidget";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const feature_flag_widget = "cashier-widget";

export default function Layout({ state, auth }) {
  const [openNavBar, setOpenNavBar] = useState(false);
  const { isMobile: mobile, simplexWidget } = state.getState();
  const location = useLocation();
  const width = useWindowSize();
  const { documentTitle, pageTitle } = state.getState();

  const isWidgetFlagActive = useFeatureIsOn(feature_flag_widget);

  useEffect(() => {
    mobile && setOpenNavBar(false);
    window.scrollTo(0, 0);

    return () => {
      window["prev_location"] = location;
    };
  }, [location]);

  useEffect(() => {
    document.title = `${documentTitle} ${pageTitle ? " – " + pageTitle : " "}`;
    return () => {};
  }, [pageTitle]);

  useEffect(() => {
    if (mobile) return;

    let scroll = ScrollBar.create();
    window["ScrollBar"] = scroll;
    return () => {
      window["ScrollBar"] = null;
      scroll.kill();
    };
  }, [mobile]);

  useEffect(() => {
    const boo = width < 1025;
    boo !== mobile && state.setDeviceType(boo);

    return () => {};
  }, [width]);

  return (
    <>
      <Header
        isMobile={mobile}
        state={state}
        openNav={setOpenNavBar}
        isWidgetActive={isWidgetFlagActive}
      />
      <Aside
        isMobile={mobile}
        state={state}
        openNav={setOpenNavBar}
        isOpen={openNavBar}
      />
      <main
        className={
          "page_" +
            pageTitle?.toLowerCase().split(" - ")[0].split(" ").join("_") || ""
        }
      >
        <Outlet />
      </main>
      <Footer />
      <PopUps state={state} />
      {auth?.token && auth?.pid && isWidgetFlagActive && (
        <WalletPopUp state={state} auth={auth} />
      )}
      {simplexWidget && <SimplexWidget state={state} />}
    </>
  );
}
