/**
 * @typedef {{ balance: string, withdrawableBalance: string, blockedBalance: string }} WithdrawableBalance
 */

import React from "react";

/**
 *
 * @param {{ isLoading, data: WithdrawableBalance, error }} param0
 * @returns
 */
export function Balance({ isLoading, data, error }) {
  if (isLoading && !data) {
    return (
      <BalanceLayout
        data={{
          balance: "Loading...",
          withdrawableBalance: "Loading...",
          blockedBalance: "Loading...",
        }}
      />
    );
  }
  if (error) {
    // handle error
    return (
      <BalanceLayout
        data={{
          balance: "?",
          withdrawableBalance: "?",
          blockedBalance: "?",
        }}
      />
    );
  }
  return <BalanceLayout data={data} />;
}

/**
 *
 * @param {{ data: WithdrawableBalance }} param0
 * @returns
 */
function BalanceLayout({ data }) {
  // 5,000
  return (
    <div className="tico-information df fd-c gap-16">
      <div className="row df jc-sb ai-c gap-10">
        <p className="fixel_14 color-neu">Current Balance</p>
        <div className="df ai-c gap-6">
          {/* <div className="tico_icon"></div> it should be removed from design */}
          <p className="fixel_16_sbl">{data.balance}</p>
        </div>
      </div>
      <div className="row df jc-sb ai-c gap-10">
        <p className="fixel_14 color-neu">
          Locked Balance (unlocks in xx days)
        </p>
        <div className="df ai-c gap-6">
          {/* <div className="tico_icon"></div> it should be removed from design */}
          <p className="fixel_16_sbl">{data.blockedBalance}</p>
        </div>
      </div>

      <div className="full-line"></div>

      <div className="row df jc-sb ai-c gap-10 fixel_22">
        <p>Available To Withdraw</p>
        <div className="df ai-c gap-6">
          <div className="tico_icon"></div>
          <p>{data.withdrawableBalance}</p>
        </div>
      </div>
    </div>
  );
}
